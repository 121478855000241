<!-- 荣誉奖项 -->
<template>
  <div class="edu-background">
    <!-- 标题 -->
    <model-title
      :title="modelData.title"
      :iconfont="modelData.iconfont"
      :model-style="modelStyle"
    ></model-title>
    <!-- 教育背景 -->
    <div class="edu-list">
      <div v-for="(item, index) in modelData.LIST" :key="index" class="list-item">
        <span v-if="modelData.isShow.date">
          {{ formatDate(item.date) }}
        </span>
        <span v-if="modelData.isShow.awardsName">
          {{ item.awardsName }}
        </span>
        <!-- 奖项等级 class="majorCourse" -->
        <span v-if="modelData.isShow.awardsGrade">{{ item.awardsGrade }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { IAWARDS } from '@/interface/model';
  import ModelTitle from '@/material/ModelTitle/ModelTitle2/ModelTitle.vue';
  import { formatDate } from '@/utils/common';
  import IMODELSTYLE from '@/interface/modelStyle';

  const props = defineProps<{
    modelData: IAWARDS;
    modelStyle: IMODELSTYLE; // 模块样式
  }>();
  console.log(props);
</script>
<style lang="scss" scoped>
  .edu-background {
    padding-left: v-bind('modelStyle.pLeftRight');
    padding-right: v-bind('modelStyle.pLeftRight');
    margin-bottom: v-bind('modelStyle.mBottom');
    margin-top: v-bind('modelStyle.mTop');
    box-sizing: border-box;
    .edu-list {
      display: flex;
      width: 100%;
      flex-direction: column;

      .list-item {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: v-bind('modelStyle.pTop');
        padding-bottom: v-bind('modelStyle.pBottom');

        font-size: v-bind('modelStyle.textFontSize');
        color: v-bind('modelStyle.textColor');
        font-weight: v-bind('modelStyle.textFontWeight');
        
        margin-bottom: v-bind('modelStyle.pTop');
        // margin-top: 20px;
        .date-school-box {
          // width: 100%;
          // display: flex;
          // justify-content: space-between;
          font-size: v-bind('modelStyle.textFontSize');
          color: v-bind('modelStyle.textColor');
          font-weight: v-bind('modelStyle.textFontWeight');
          
          margin-bottom: v-bind('modelStyle.pTop');
          span {
            min-width: 165px;
            &:last-child {
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
        .majorCourse {
          // width: 100%;
          // display: flex;
          font-size: v-bind('modelStyle.textFontSize');
          color: v-bind('modelStyle.textColor');
          font-weight: v-bind('modelStyle.textFontWeight');
          line-height: 18px;
          text-align: justify;
        }
      }
    }
  }
</style>
